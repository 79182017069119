/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Frutiger LT';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Roman'), url('Frutiger LT 55 Roman.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Italic'), url('Frutiger LT 56 Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Condensed'), url('Frutiger LT 57 Condensed.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Condensed Italic'),
    url('Frutiger LT 58 Condensed Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Light';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Light'), url('Frutiger LT 45 Light.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Light Italic'), url('Frutiger LT 46 Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Light Condensed'),
    url('Frutiger LT 47 Light Condensed.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Light Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Light Condensed Italic'),
    url('Frutiger LT 48 Light Condensed Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT';
  font-style: normal;
  font-weight: 700;
  src: local('Frutiger LT Bold'), url('Frutiger LT 65 Bold.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Bold Italic'), url('Frutiger LT 66 Bold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Bold Condensed'), url('Frutiger LT 67 Bold Condensed.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Bold Condensed Italic'),
    url('Frutiger LT 68 Bold Condensed Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Black';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Black'), url('Frutiger LT 75 Black.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Black Italic'), url('Frutiger LT 76 Black Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Black Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Black Condensed'),
    url('Frutiger LT 77 Black Condensed.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Black Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Black Condensed Italic'),
    url('Frutiger LT 78 Black Condensed Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Extra Black Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Extra Black Condensed Italic'),
    url('Frutiger LT 88 Extra Black Condensed Italic.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Extra Black Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Extra Black Condensed'),
    url('Frutiger LT 87 Extra Black Condensed.woff') format('woff');
}

@font-face {
  font-family: 'Frutiger LT Ultra Black';
  font-style: normal;
  font-weight: normal;
  src: local('Frutiger LT Ultra Black'), url('Frutiger LT 95 Ultra Black.woff') format('woff');
}
